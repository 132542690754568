import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  language = new EventEmitter<any>();
  authUser: any = {};

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService,
    private router: Router
  ) {
  }

  enable2FA(): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/2fa/enable', {});
  }

  confirm2FA(code): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/2fa/confirm', {code});
  }

  disable2FA(): Observable<any> {
    return this.http.delete(environment.apiUrl + 'auth/2fa/disable');
  }

  regenerate2FACodes(): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/2fa/regenerate-codes', {});
  }

  isAdmin() {
    if (this.authUser.kmAdmin || ( this.authUser.storeAdmin && this.authUser.storeAdmin.indexOf(environment.store_id) > -1) ) {
      return true;
    }
    return false;
  }

  getInstaLoggedInUrl(page) {
    return environment.instaUrl + '/' + page + '?access_token=' + localStorage.getItem('ng2-ui-auth_token');
  }

  checkEmail(email) {
    return this.http.post(`${environment.apiUrl}auth/checkuser`, {username: email});
  }

  getMe() {
    return this.http.get(`${environment.apiUrl}auth/me`);
  }

  getCurrentLang() {
    return this.translate.currentLang;
  }

  setLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem('km2Language', lang);
  }

  getAll(params: {}) {
    return this.http.get(`${environment.apiUrl}users`, {params});
  }

  getById(id: number) {
    return this.http.get(`${environment.apiUrl}users/` + id);
  }

  getByUsername(username: string) {
    return this.http.get(`${environment.apiUrl}users/` + username);
  }

  register(user: any) {
    return this.http.post(`${environment.apiUrl}auth/register`, user);
  }

  update(user: any) {
    return this.http.put(`${environment.apiUrl}users/` + user.id, user);
  }

  superUpdate(data: {id: number, firstname: string, lastname: string, status: number, email: string}): Observable<any> {
    return this.http.put(`${environment.apiUrl}users/` + data.id + '/super', data);
  }

  logout() {
    this.auth.logout().subscribe(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('ng2-ui-auth_token');
      this.authUser = {};
    }, () => {
      localStorage.removeItem('user');
      localStorage.removeItem('ng2-ui-auth_token');
      this.authUser = {};
    });
  }

  lostPassword(params: any) {
    params.store_id = environment.store_id;
    params.langCode = this.getCurrentLang();

    return this.http.post(`${environment.apiUrl}auth/lostpw`, params);
  }

}
