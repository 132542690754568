import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  maintenanceModeActive = false;

  constructor(
    private userService: UserService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private authService: AuthService,
  ) {}

  ngOnInit() {

    // If maintenance mode is activated when app starts, listen for key events to toggle it off
    if (this.maintenanceModeActive) {
      // Set listener to listen for all key events
      // If user types 'kmskippi' maintenance mode will be toggled off
      let keys = '';
      document.addEventListener('keydown', (event) => {
        keys += event.key;
        if (keys.includes('kmskippi')) {
          this.maintenanceModeActive = false;
          keys = '';
        }
      });
    }

    this.angulartics2GoogleTagManager.startTracking();

    // reset courses filters on startup
    localStorage.setItem('km2Filters', JSON.stringify({}));

    if (this.authService.isAuthenticated()) {
      this.userService.authUser = JSON.parse(localStorage.getItem('user'));
      this.userService.getMe().subscribe((res: any) => {
        this.userService.authUser = res;
        localStorage.setItem('user', JSON.stringify(res));
      }, (error) => {
        console.error(error);
      });
    }
  }
}
