<router-outlet *ngIf="!maintenanceModeActive"></router-outlet>
<div class="container" *ngIf="maintenanceModeActive">
  <div class="row vh-100">
    <div class="col-md-12 vh-100 d-flex align-items-center justify-content-around">
      <div class="card shadow">
        <div class="card-body">
          <img style="max-height: 100px;" src="/assets/img/kiwa_koulutusmaailma_logo.svg" alt="">
          <h3>Koulutusmaailma</h3>
          <p style="font-size: 1.4em;">Suoritamme Koulutusmaailmassa järjestelmäpäivitystä tiistaina 10.12.2024 klo 12.00–21.00. Tänä aikana Koulutusmaailma ei ole käytettävissä, mukaan lukien verkkokoulutusten suorittaminen.</p>
          <hr style="border-top: 2px solid black">
          <p style="font-size: 1.4em;">We will perform a system update in Koulutusmaailma on Tuesday, December 10, 2024, from 12:00 PM to 9:00 PM. During this time, Koulutusmaailma will be unavailable, including the completion of online courses.</p>
        </div>
      </div>
    </div>
  </div>
</div>
