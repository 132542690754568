import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";
/*
Sentry.init({
  dsn: environment.sentryApiUrl,
  environment: environment.sentryEnv,
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0,
  integrations: [new Sentry.BrowserTracing()],
});
*/
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));