export const environment = {
    production: false,
    envName: 'release',
    kmUrl: 'https://km.release.kiwa.dev',
    apiUrl: 'https://api.km.release.kiwa.dev/v2/',
    instaUrl: 'https://impact.release.kiwa.dev',
    stripeKey: 'pk_test_HkLQuBi1VP1eThdNxKBHDJMO',
    store_id: 1,
    store_name: 'Koulutusmaailma',
    allowedDomains: ['api.km.release.kiwa.dev'],
    tinyMCEApiKey: 'lhu8rgyop1jcigmfttczz23wmqjieu9oloa6oorjn1i0t01i',
    sentryEnv: 'release',
    sentryApiUrl: 'https://c8f0bab8614a643c7aef7ec40977b8fd@sentry.kiwa.dev:9000/7'
};
